.App {
  text-align: center;
}

td {
  border: 2px solid white;
  border-collapse: collapse;
  text-align: center;
  align-items: center;
  padding: 5px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-top: 10px;
  margin-left: 10px;
}

.btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.error {
  color: rgb(196, 9, 9);
  font-size: large;
  font-weight: 500;
  border-radius: 100px;
  margin-bottom: 5px;
}

/* mona code */

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.dropdown {
  text-align: left;
  color: aliceblue;
  background-color: rgb(50, 93, 172);
  padding: 10px;
  margin-top: 10px;
  width: 10%;
  height: fit-content;
  margin-left: 89%;
  box-shadow: 3px 3px black;
  scroll-behavior: smooth;
  position: absolute;
  margin-right: 30px;

  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.dropdown p {
  cursor: pointer;
  margin-bottom: 5px;
}

.site-header {
  display: flex;
  /* padding: 5px 5px; */
  background-color: #0d1a3ef0;
  color: aliceblue;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 90px;
}

.site-header p {
  font-size: 30px;
}

.site-header a {
  margin-left: 10px;
}

.site-header img {
  margin-right: 20px;
}

/* login form start  */

.div_container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: linear-gradient(79deg, #7434db,#c66fbc 48%,#F7944d); */
  background-color: #0d1a3ef0;
  width: 100%;
  height: 150vh;
}

.div_container_new_candidate_form {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0d1a3ef0;
  width: 100%;
  height: 100vh;
}

.form-container {
  text-align: center;
  padding: 1rem 2rem;
  border: 3px solid white;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgb(64, 204, 242);
  width: 60rem;
  height: fit-content;
  background-color: black;
  color: aliceblue;
}

.form-divider {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-container h1 {
  font-size: 35px;
  color: rgb(50, 93, 172);
  text-shadow: 0 10px 20px rgb(64, 204, 242);
}


.form_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 40rem;
}

.Login-form {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  position: relative; */

  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .Login-form label {
  display: flex;
  flex-direction: column;
  text-align: center;


} */

.Login-form input {
  float: right;
  margin: 15px 0;
  padding: 5px;
  border: none;
  border-radius: 10px;
  color: black;
}
.Login-form button {
  width: 10rem;
  height: 45px;
}


.form-divider1 {
  display: flex;
  flex-direction: column;
  justify-content: end;

}

.User-DropDown {
  margin-top: 10px;
  color: black;
  width: 250px
}


.state-dist {
  color: white;
  margin-top: 15px;
  margin-bottom: 10px;
}

.state-dist option {
  color: black;
}

.state-dist select {
  color: black;
  margin-left: 10px;
  width: 17rem;
  height: 30px;
  border-radius: 5px;
}

/* newcandidate form */





/* edit from */
.Edit_Form {
  position: fixed;
  display: block;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto 5px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow-x: hidden;
  background-color: rgba(31,32,41,.75);
  pointer-events: none;
 opacity: 1;
  transition: all 300ms ease-in-out;
  pointer-events: auto;
}